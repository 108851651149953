import { useMemo } from "react";
import countryList from "react-select-country-list";

const useCountry = () => {
  const options = useMemo(() => {
    let options = countryList().getData() as Array<{ value: string; label: string }>;
    const index = options.findIndex((c) => c.value === "DE");

    if (index > -1) {
      const germany = options[index];
      options = [germany, ...options.slice(0, index), ...options.slice(index + 1)];
    }
    return options;
  }, []);

  return options;
};

export default useCountry;
