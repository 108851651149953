import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { FilterComparator, FilterPublicPharmacyInput, SortDirection } from "../api/generated";
import { useGraphql } from "./useGraphql";
import { ValueProps } from "@gruene-brise/common-ui/Dropdown/types";

const usePublicPharmacyList = () => {
  const [options, setOptions] = useState<ValueProps[]>([]);
  const { filterPublicPharmacies } = useGraphql();
  const filterPublicPharmaciesMutation = useMutation((input: FilterPublicPharmacyInput) =>
    filterPublicPharmacies({ input }),
  );
  const isLoading = filterPublicPharmaciesMutation.isLoading;
  const fetchPharmacy = useCallback(async (search?: string) => {
    const { filterPublicPharmacies } = await filterPublicPharmaciesMutation.mutateAsync({
      filter: {
        filter: {
          nodes: search
            ? [
                {
                  field: "name",
                  value: `${search}`,
                  comparator: FilterComparator.Like,
                },
              ]
            : [],
        },
        sort: [
          {
            direction: SortDirection.Asc,
            field: "name",
          },
        ],
      },
    });
    const options =
      filterPublicPharmacies?.results?.map((result) => ({
        id: result.id.toString(),
        value: result.id.toString(),
        label: result.name,
      })) || [];
    setOptions(options);
  }, []);

  useEffect(() => {
    fetchPharmacy();
  }, []);
  return { fetchPharmacy, isLoading, options };
};

export default usePublicPharmacyList;
