import React from "react";

const HeaderSearchIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      className='w-[14px] h-[16px] xl:w-[16px] xl:h-[18px] lg:w-[16px] lg:h-[18px] md:w-[16px] md:h-[18px]'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.95316 0.77128C7.64114 0.770661 6.35829 1.14397 5.26635 1.84413C4.17441 2.54429 3.32226 3.53997 2.81733 4.70564C2.31239 5.87131 2.17729 7.15479 2.42903 8.39427C2.68078 9.63375 3.30811 10.7737 4.23194 11.6705L0.597683 15.7322C0.448147 15.9222 0.37956 16.16 0.405916 16.397C0.432272 16.634 0.551584 16.8524 0.739508 17.0076C0.927433 17.1629 1.16981 17.2432 1.41717 17.2323C1.66454 17.2214 1.89825 17.12 2.07063 16.9489L5.78947 12.7926C6.67938 13.2562 7.66751 13.5181 8.67852 13.5583C9.68952 13.5986 10.6967 13.4161 11.6234 13.0249C12.55 12.6336 13.3716 12.0438 14.0256 11.3006C14.6795 10.5573 15.1486 9.68016 15.397 8.73594C15.6454 7.79173 15.6666 6.80538 15.4589 5.85209C15.2513 4.89881 14.8203 4.00373 14.1988 3.2351C13.5772 2.46646 12.7816 1.84454 11.8726 1.41676C10.9635 0.988972 9.96503 0.766604 8.95316 0.766602V0.77128ZM8.95316 11.6977C8.02281 11.6977 7.11335 11.4321 6.33981 10.9345C5.56626 10.437 4.96338 9.72977 4.60739 8.90238C4.25141 8.07498 4.15832 7.16454 4.33989 6.28621C4.52147 5.40788 4.96954 4.60111 5.62747 3.96793C6.28539 3.33475 7.1236 2.90359 8.0361 2.72899C8.9486 2.55439 9.89439 2.64419 10.7539 2.98703C11.6133 3.32987 12.3479 3.91035 12.8646 4.65507C13.3814 5.39978 13.6571 6.27527 13.6569 7.17082C13.6553 8.37112 13.1592 9.52182 12.2774 10.3705C11.3956 11.2191 10.2001 11.6964 8.95316 11.6977Z'
        fill={color ?? "#919C8B"}
      />
    </svg>
  );
};

export default HeaderSearchIcon;
