/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Unit } from "@gruene-brise/data-access/api/generated";
import environment from "@gruene-brise/data-access/config/environment";
import useCartManager from "@gruene-brise/data-access/hooks/useCartManager";
import useCurrencyFormatter from "@gruene-brise/data-access/hooks/useCurrencyFormatter";
import { useStatusTranslations } from "@gruene-brise/data-access/hooks/useStatusTranslations";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import usePrice from "../../../../data-access/src/lib/hooks/usePrice";
import FormButton from "../form/FormButton";
import AddCartControl from "./AddCartControl";
import ProductCard, { ProductCardProps } from "./ProductCard";
import { useQuantityUnitValue } from "@gruene-brise/data-access/hooks/useQuantity";

/**
 * Product Modal Card shows a pop up with the product card for users to include the number of quantity they want
 */
export interface ProductCardModalProps extends ProductCardProps {
  visibility?: boolean;
  onClose?(): void;
  className?: string;
}

const ProductCardModal = ({ visibility, onClose, className, ...rest }: ProductCardModalProps) => {
  const { t } = useTranslation();
  const { productUnitTranslation } = useStatusTranslations();

  const unit = productUnitTranslation(rest?.unit ?? Unit.Gram);

  const { addProductToCart, isItemAlreadyInCart, toogleCart } = useCartManager();
  const { calculatePrice } = usePrice();
  const [gram, setGram] = useState(0);
  const numberFormat = useCurrencyFormatter();
  const priceFormat = numberFormat(calculatePrice(rest, gram));
  const maxUnitValue = environment.maximumNumberOfCartItem;

  const addToCart = useCallback(() => {
    if (isItemAlreadyInCart(rest.id)) return;
    if (gram <= 0) return;

    addProductToCart({
      addedAt: new Date(),
      id: rest.id,
      product: { ...rest },
      selection: gram > 0 ? gram : 1,
    });

    toogleCart(true);
    onClose?.();
    setGram(0);
  }, [isItemAlreadyInCart, gram]);

  const getQuantityUnitValue = useQuantityUnitValue();

  return (
    <div
      className={`
        ${!visibility ? "hidden" : ""}
        overflow-y-auto overflow-x-hidden flex flex-col items-center fixed top-0 right-0 left-0 md:inset-0 h-modal md:h-full`}
      style={{ zIndex: 1000000, backgroundColor: "rgba(0,0,0,0.2)" }}
    >
      <div
        className={`
          relative p-4 w-auto w-xl h-screen
          ${className}
        `}
        style={{ zIndex: 10000 }}
      >
        <div className='relative bg-tertiary-10 px-8 pb-3 top-[0%] xl:top-[30%] lg:top-[30%] md:top-[30%] pt-[54px] rounded-xl shadow flex flex-col items-center'>
          <div className='w-full flex flex-col md:flex-row gap-2 md:gap-14 items-center justify-between'>
            <ProductCard
              {...rest}
              cardWrapperClass='shadow-button_xl shadow-inner -mb-10'
              showCartButton={false}
              productCardWrapperClass='!shadow-button_xl'
            />

            <div className='w-[400px] mt-10 xl:-mt-10 lg:-mt-10 md:-mt-14 flex flex-col items-center justify-center font-gellix'>
              <div className='text-center font-semibold text-xl xl:text-2xl text-primary w-[90%] relative line-clamp-2 font-gellix'>
                {t("Please specify quantity")}
              </div>
              <div className='pt-5 text-center w-full text-[7px] sm:text-xs md:text-xs lg:text-xs xl:text-sm font-small text-primary-50 line-clamp-3 font-gellix'>
                {t("Confirm the prescribed amount before adding the product to your cart.")}
              </div>

              <div className='px-8 my-2 w-full md:px-2'>
                <AddCartControl
                  onClickMinus={() =>
                    setGram((prev) => (prev - rest.unitValue < 0 ? prev : prev - rest.unitValue))
                  }
                  onClickPlus={() =>
                    setGram((prev) =>
                      prev + rest.unitValue > maxUnitValue ? prev : prev + rest.unitValue,
                    )
                  }
                  price={priceFormat}
                  priceDescription={getQuantityUnitValue({
                    ...rest,
                  })}
                  showButtons={true}
                  setGram={setGram}
                  gram={gram}
                  unitValue={rest?.unitValue}
                  unit={unit}
                  showPriceDescription
                  bgColor='bg-white'
                />
              </div>

              <div className='w-full px-8 md:px-0 relative'>
                <div className=' py-6 pb-3'>
                  <FormButton
                    isDisabled={gram <= 0}
                    title={t("Add to Cart")!}
                    onPress={() => {
                      addToCart();
                    }}
                  />
                </div>

                <div className=''>
                  <FormButton
                    title={t("Cancel")!}
                    outline
                    onPress={() => {
                      onClose?.();
                      setGram(0);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardModal;
