import { create } from "zustand";
import { combine, createJSONStorage, persist } from "zustand/middleware";

const defaultState: {
  toastShown: boolean;
} = {
  toastShown: false,
};

export const useChristmasToastState = create(
  persist(
    combine(defaultState, (set) => ({
      setToastShown: (toastShown: boolean) => set((state) => ({ ...state, toastShown })),
    })),
    {
      storage: createJSONStorage(() => sessionStorage),
      name: "christmas-toast",
    },
  ),
);
