import {
  ProductAvailabilityOptions,
  ProductCategory,
} from "@gruene-brise/data-access/api/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FilterExpression,
  FilterOperatorFactory,
  Sorting,
} from "libs/data-access/src/lib/api/filter";
import { WebshopProductFilterFields } from "libs/data-access/src/lib/types";
import { curateFilterComparators } from "libs/data-access/src/lib/utils";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useProductFilterOptions } from "./useproductFilter";
import { useRouter } from "next/router";
import { useProductFiltersState } from "@gruene-brise/data-access/state/useProductFiltersState";

export type FormProps = {
  price_from: string;
  price_to: string;
  search: string;
  strain: string;
  thcFrom: string;
  thcTo: string;
  cbdFrom: string;
  cbdTo: string;
  availability: string;
  availableInDaysFrom: string;
  availableInDaysTo: string;
  availableFrom: string;
  genetics: string;
  manufacturer: string;
  origin: string;
  irradiation: string;
  pharmacyId: string;
  category: string;
};

export type UseProductFilterFormReturnType = ReturnType<typeof useProductFilterFormState>;

export const useProductFilterFormState = (
  captureFilters?: (values: {
    filters: FilterExpression<WebshopProductFilterFields>;
    search?: string;
    pharmacyId?: string;
    sort?: Sorting<WebshopProductFilterFields>[];
  }) => void,
  ...callbacks: (() => void)[]
) => {
  const { filterOptions } = useProductFilterOptions();
  const { handleSort, sort } = useProductSortState();
  const { t } = useTranslation();
  const router = useRouter();
  const {
    setProductFilters,
    setPharmacyId,
    filters,
    pharmacyId: queryPharmacyId,
  } = useProductFiltersState();

  const form = useForm<FormProps>({
    defaultValues: {
      search: "",
      strain: "",
      thcFrom: "",
      thcTo: "",
      cbdFrom: "",
      cbdTo: "",
      price_from: "",
      price_to: "",
      genetics: "",
      irradiation: "",
      pharmacyId: "",
      category: "",
    },
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(
      yup
        .object({
          search: yup.string().nullable().notRequired(),
          pharmacyId: yup.string().nullable().notRequired(),
          strain: yup.string().nullable().notRequired(),
          thcFrom: yup
            .number()
            .min(1)
            .nullable()
            .notRequired()
            .transform((_, val) => (val ? Number(val) : null)),
          thcTo: yup
            .number()
            .min(1)
            .nullable()
            .notRequired()
            .transform((_, val) => (val ? Number(val) : null)),
          cbdFrom: yup
            .number()
            .min(1)
            .nullable()
            .notRequired()
            .transform((_, val) => (val ? Number(val) : null)),
          cbdTo: yup
            .number()
            .min(1)
            .nullable()
            .notRequired()
            .transform((_, val) => (val ? Number(val) : null)),
          price_from: yup
            .number()
            .min(1)
            .nullable()
            .notRequired()
            .transform((_, val) => (val ? Number(val) : null)),
          price_to: yup
            .number()
            .min(1)
            .nullable()
            .notRequired()
            .transform((_, val) => (val ? Number(val) : null)),

          category: yup
            .string()
            .oneOf([ProductCategory.Capsule, ProductCategory.Extract, ProductCategory.Flower])
            .transform((val) => (typeof val === "string" && val.length === 0 ? null : val))
            .nullable()
            .notRequired(),
          availability: yup
            .string()
            .oneOf([
              ProductAvailabilityOptions.AvailableFrom,
              ProductAvailabilityOptions.AvailableIn,
              ProductAvailabilityOptions.ImmediatelyAvailable,
              ProductAvailabilityOptions.Unavailable,
              ProductAvailabilityOptions.AvailableFrom.concat(
                ",",
                ProductAvailabilityOptions.AvailableIn,
              ), // TODO: Remove when both values become filterable
            ])
            .transform((val) => (typeof val === "string" && val.length === 0 ? null : val))
            .nullable()
            .notRequired(),
          availableInDaysFrom: yup
            .number()
            .min(1)
            .when("availability", {
              is: ProductAvailabilityOptions.AvailableIn,
              then(schema) {
                return schema.required(t("Days range is required"));
              },
              otherwise(schema) {
                return schema.nullable().notRequired();
              },
            })
            .transform((_, val) => (val ? Number(val) : null)),
          availableInDaysTo: yup
            .number()
            .min(1)
            .nullable()
            .notRequired()
            .transform((_, val) => (val ? Number(val) : null)),
          availableFrom: yup.date().when("availability", {
            is: ProductAvailabilityOptions.AvailableFrom,
            then(schema) {
              return schema.required(t("Available date is required"));
            },
            otherwise(schema) {
              return schema.nullable().notRequired();
            },
          }),
          genetics: yup.string().nullable().notRequired(),
          irradiation: yup.string().nullable().notRequired(),
        })
        .notRequired(),
    ),
  });

  const isSelected = (field: keyof FormProps, value: string) => {
    return form.getValues(field) === value;
  };

  const handleSelection = (field: keyof FormProps, value: string) => {
    const currentValue = form.getValues(field);
    let newValue: string = value;
    if (currentValue?.includes(value)) {
      newValue = currentValue.replace(value, "");
    }
    form.setValue(field, newValue, { shouldValidate: true });
  };

  const handleFilter = useCallback(
    (e?: any) => {
      e?.preventDefault();
      const values = form.getValues();
      const allSorts = Object.keys(sort)
        .filter((field) => Boolean(sort[field].direction))
        .map((field) => ({
          direction: sort[field].direction,
          field: field as keyof WebshopProductFilterFields,
        }));

      const comparators = curateFilterComparators(filterOptions, form.getValues());
      const filterBuilder = FilterOperatorFactory.and(undefined, ...comparators);
      captureFilters?.({
        filters: filterBuilder.build(),
        search: values.search,
        pharmacyId: values.pharmacyId,
        sort: allSorts,
      });
      setProductFilters(filterBuilder.build());
      setPharmacyId(values.pharmacyId);
      if (filterBuilder.build().nodes?.length || values.pharmacyId) {
        router.push({
          pathname: "/",
          query: { filters: JSON.stringify(filterBuilder.build()), pharmacyId: values.pharmacyId },
        });
      }
      callbacks.forEach((callback) => callback());
    },
    [form.getValues, filterOptions, callbacks.length, sort],
  );

  return { form, sort, handleFilter, isSelected, handleSelection, handleSort };
};

interface SortFields
  extends Pick<
    WebshopProductFilterFields,
    "availability" | "origin" | "manufacturer" | "genetics" | "irradiation" | "startingPrice"
  > {
  thcPercentage: string;
  cbdPercentage: string;
}
type SortProps = {
  [key in keyof SortFields]?: {
    direction?: "asc" | "desc";
    label: string;
  };
};

export const useProductSortState = () => {
  const { t } = useTranslation();
  const [sort, setSort] = useState<SortProps>({
    availability: { label: t("Availability"), direction: "asc" },
    startingPrice: { label: t("Price"), direction: "asc" },
    thcPercentage: { label: t("THC") },
    cbdPercentage: { label: t("CBD") },
    genetics: { label: t("Genetics") },
    irradiation: { label: t("Irradiation") },
    origin: { label: t("Origin") },
    manufacturer: { label: t("Manufacturer") },
  });

  const handleSort = (field: keyof typeof sort) => {
    setSort((prev) => {
      if (!prev[field].direction) {
        return { ...prev, [field]: { ...prev[field], direction: "asc" } };
      } else if (prev[field]?.direction === "asc") {
        return { ...prev, [field]: { ...prev[field], direction: "desc" } };
      } else {
        return { ...prev, [field]: { ...prev[field], direction: undefined } };
      }
    });
  };

  return { sort, handleSort };
};
