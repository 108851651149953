/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useMediaQuery } from "@chakra-ui/react";
import WebshopNavigation from "@gruene-brise/common-ui/lib/navigation/WebshopNavigation";
import { useRouter } from "next/router";
import LiveBestand from "../components/LiveBestand";
import { useEffect } from "react";
import useCartManager from "@gruene-brise/data-access/hooks/useCartManager";

export default function Index() {
  const router = useRouter();
  const [isSmallScreen] = useMediaQuery("(min-width: 640px)");

  const search = router.query["search"] as string;

  const { query } = useRouter();
  const { emptyCart } = useCartManager();

  useEffect(() => {
    if (query?.success) {
      emptyCart();
    }
  }, [query]);

  return (
    <WebshopNavigation isDark showTopMenu={isSmallScreen && router.pathname === "/"}>
      <LiveBestand search={search} />
    </WebshopNavigation>
  );
}
