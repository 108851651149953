import React from "react";
const AddToCartIcon = () => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.93672 8.66262H11.5664V6.21818H14.0108V4.58855H11.5664V2.1441H9.93672V4.58855H7.49228V6.21818H9.93672V8.66262ZM6.67746 15.996C5.78117 15.996 5.05598 16.7293 5.05598 17.6256C5.05598 18.5219 5.78117 19.2552 6.67746 19.2552C7.57376 19.2552 8.30709 18.5219 8.30709 17.6256C8.30709 16.7293 7.57376 15.996 6.67746 15.996ZM14.8256 15.996C13.9293 15.996 13.2041 16.7293 13.2041 17.6256C13.2041 18.5219 13.9293 19.2552 14.8256 19.2552C15.7219 19.2552 16.4552 18.5219 16.4552 17.6256C16.4552 16.7293 15.7219 15.996 14.8256 15.996ZM7.57376 11.9219H13.6441C14.2552 11.9219 14.793 11.5878 15.0701 11.0826L18.2152 5.37077L16.7975 4.58855L13.6441 10.2923H7.92413L4.45302 2.95892H1.78857V4.58855H3.4182L6.35154 10.773L5.25154 12.7611C4.65672 13.853 5.43894 15.1811 6.67746 15.1811H16.4552V13.5515H6.67746L7.57376 11.9219Z'
        fill='white'
      />
    </svg>
  );
};

export default AddToCartIcon;
