import { useCallback, useEffect, useState } from "react";
import useWindowDimensionHook from "./useWindowDimensionHook";

/**
 * Checks if mobile is used.
 * Mobile is defined as less than 800px.
 * @returns bool
 */
export default function useMobile(width = 800) {
  const size = useWindowDimensionHook();
  const checkForMobile = useCallback(() => {
    return size.width < width;
  }, [size.width]);

  const [isMobile, setIsMobile] = useState(checkForMobile);

  useEffect(() => {
    if (checkForMobile()) setIsMobile(true);
    else setIsMobile(false);
  }, [checkForMobile]);

  return isMobile;
}
